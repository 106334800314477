import React from "react";
import { Error, LinearProgress } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardActions, CardContent, Grid, Typography } from "@material-ui/core";
import MoneyAmountField from "../../../../../Field/Wallet/MoneyAmountField";

const useStyles = makeStyles({
    walletCard: {
        width: '250px'
    },
    amountText: {
        fontSize: '20px'
    },
    walletId: {
        fontSize: '9pt',
        marginTop: '0.25em',
        marginLeft: '0.5em',
    }
});

const getTypeLabel = (type: number): string => {
    switch (type) {
        case 1: return 'Savings'
        case 2: return 'Investments'
        default: return 'Undefined'
    }
}

const WalletCard = (props) => {
    const { ids, error, data, loaded } = props;
    const classes = useStyles();

    if (!loaded) return <LinearProgress />;
    if (error) return <Error error={error}/>;
    if (!data) return null;

    return (
        <Grid container spacing={3}>
            {ids.map(id =>
                <Grid item key={id}>
                    <Card variant="outlined" className={classes.walletCard}>
                        <CardContent>
                            <Typography color="textSecondary" variant="subtitle2">Balance</Typography>
                            <MoneyAmountField record={data[id]} className={classes.amountText} />
                        </CardContent>
                        <CardActions>
                            <Typography color="textSecondary" className={classes.walletId}>
                                Type: {data[id].type ? getTypeLabel(data[id].type) : 'Wallet'}
                            </Typography>
                        </CardActions>
                    </Card>
                </Grid>
                )
            }
        </Grid>
    )
}

export default WalletCard;
