import {
    AssignmentReturned,
    BorderColor,
    CallMade,
    DoneAll,
    InsertComment,
    ImportContacts,
    LibraryBooks,
    ListAlt,
    LiveHelp,
    MenuBook,
    QuestionAnswer,
    VerifiedUser,
    Group,
    AccountBalanceWallet,
    MonetizationOn,
    FontDownload,
    School,
    AccountBalance,
    NotificationsActive,
} from '@material-ui/icons';

import { AdminEdit } from "./component/Resource/Admin/View/AdminEdit";
import { AdminsList } from "./component/Resource/Admin/View/AdminsList";
import { AdminShow } from "./component/Resource/Admin/View/AdminShow";
import { AdminCreate } from "./component/Resource/Admin/View/AdminCreate";
import { UsersList } from "./component/Resource/User/View/UsersList";
import { FeedbackList } from "./component/Resource/Feedback/View/FeedbackList";
import { KycAnswerList } from "./component/Resource/KycAnswers/View/KycAnswerList";
import { UserAlertnessList } from "./component/Resource/UserAlertness/View/UserAlertnessList";
import { BankAccountList } from "./component/Resource/BankAccounts/View/BankAccountList";
import { BlockedBankAccountList } from "./component/Resource/BankAccounts/View/BlockedBankAccountList";
import AccountEdit from "./component/Resource/BankAccounts/View/AccountEdit";
import { UserReferralList } from "./component/Resource/UserReferrals/View/UserReferralList";
import { LessonTopicCreate } from "./component/Resource/LessonTopic/View/LessonTopicCreate";
import { LessonTopicEdit } from "./component/Resource/LessonTopic/View/LessonTopicEdit";
import { LessonTopicShow } from "./component/Resource/LessonTopic/View/LessonTopicShow";
import { LessonTopicsList } from "./component/Resource/LessonTopic/View/LessonTopicsList";
import { LessonCreate } from "./component/Resource/Lesson/View/LessonCreate";
import { LessonEdit } from "./component/Resource/Lesson/View/LessonEdit";
import { LessonShow } from "./component/Resource/Lesson/View/LessonShow";
import { LessonsList } from "./component/Resource/Lesson/View/LessonsList";
import { RegistrationReasonCreate } from "./component/Resource/RegistrationReason/View/RegistrationReasonCreate";
import { RegistrationReasonEdit } from "./component/Resource/RegistrationReason/View/RegistrationReasonEdit";
import { RegistrationReasonList } from "./component/Resource/RegistrationReason/View/RegistrationReasonList";
import { LessonMaterialCreate } from "./component/Resource/LessonMaterial/View/LessonMaterialCreate";
import { LessonMaterialEdit } from "./component/Resource/LessonMaterial/View/LessonMaterialEdit";
import { LessonMaterialShow } from "./component/Resource/LessonMaterial/View/LessonMaterialShow";
import { LessonMaterialsList } from "./component/Resource/LessonMaterial/View/LessonMaterialsList";
import { LessonQuestionCreate } from "./component/Resource/LessonQuestion/View/LessonQuestionCreate";
import { LessonQuestionEdit } from "./component/Resource/LessonQuestion/View/LessonQuestionEdit";
import { LessonQuestionShow } from "./component/Resource/LessonQuestion/View/LessonQuestionShow";
import { LessonQuestionsList } from "./component/Resource/LessonQuestion/View/LessonQuestionsList";
import { LessonQuestionAnswerCreate } from "./component/Resource/LessonQuestionAnswer/View/LessonQuestionAnswerCreate";
import { LessonQuestionAnswerEdit } from "./component/Resource/LessonQuestionAnswer/View/LessonQuestionAnswerEdit";
import { LessonQuestionAnswerShow } from "./component/Resource/LessonQuestionAnswer/View/LessonQuestionAnswerShow";
import { LessonQuestionAnswersList } from "./component/Resource/LessonQuestionAnswer/View/LessonQuestionAnswersList";
import { UserShow } from "./component/Resource/User/View/UserShow";
import { UserEdit } from "./component/Resource/User/View/UserEdit";
import { FeedbackEdit } from "./component/Resource/Feedback/View/FeedbackEdit";
import { FaqCreate } from "./component/Resource/Faq/View/FaqCreate";
import { FaqEdit } from "./component/Resource/Faq/View/FaqEdit";
import { FaqShow } from "./component/Resource/Faq/View/FaqShow";
import { FaqsList } from "./component/Resource/Faq/View/FaqsList";
import { PageCreate } from "./component/Resource/Page/View/PageCreate";
import { PageEdit } from "./component/Resource/Page/View/PageEdit";
import { PageShow } from "./component/Resource/Page/View/PageShow";
import { PagesList } from "./component/Resource/Page/View/PagesList";
import { LessonTestAttemptCreate } from "./component/Resource/LessonTestAttempt/View/LessonTestAttemptCreate";
import { LessonTestAttemptEdit } from "./component/Resource/LessonTestAttempt/View/LessonTestAttemptEdit";
import { LessonTestAttemptShow } from "./component/Resource/LessonTestAttempt/View/LessonTestAttemptShow";
import { LessonTestAttemptsList } from "./component/Resource/LessonTestAttempt/View/LessonTestAttemptsList";
import { LessonProgressLevelCreate } from "./component/Resource/LessonProgressLevel/View/LessonProgressLevelCreate";
import { LessonProgressLevelEdit } from "./component/Resource/LessonProgressLevel/View/LessonProgressLevelEdit";
import { LessonProgressLevelShow } from "./component/Resource/LessonProgressLevel/View/LessonProgressLevelShow";
import { LessonProgressLevelsList } from "./component/Resource/LessonProgressLevel/View/LessonProgressLevelsList";
import { TipCreate } from "./component/Resource/Tip/View/TipCreate";
import { TipEdit } from "./component/Resource/Tip/View/TipEdit";
import { TipShow } from "./component/Resource/Tip/View/TipShow";
import { TipsList } from "./component/Resource/Tip/View/TipsList";
import { TipCompletionCreate } from "./component/Resource/TipCompletion/View/TipCompletionCreate";
import { TipCompletionEdit } from "./component/Resource/TipCompletion/View/TipCompletionEdit";
import { TipCompletionShow } from "./component/Resource/TipCompletion/View/TipCompletionShow";
import { TipCompletionsList } from "./component/Resource/TipCompletion/View/TipCompletionsList";
import { TaskCreate } from "./component/Resource/Task/View/TaskCreate";
import { TaskEdit } from "./component/Resource/Task/View/TaskEdit";
import { TaskShow } from "./component/Resource/Task/View/TaskShow";
import { TasksList } from "./component/Resource/Task/View/TasksList";
import { WalletTransactionsList } from "./component/Resource/WalletTransactions/View/WalletTransactionsList";
import { WalletTransactionShow } from "./component/Resource/WalletTransactions/View/WalletTransactionShow";
import { TopUpTransactionsList } from "./component/Resource/TopUpTransactions/View/TopUpTransactionsList";
import { TopUpTransactionShow } from "./component/Resource/TopUpTransactions/View/TopUpTransactionShow";
import { BankingTransactionsList } from "./component/Resource/BankingTransaction/View/BankingTransactionsList";
import { BankingTransactionShow } from "./component/Resource/BankingTransaction/View/BankingTransactionShow";
import { BankingTransactionEdit } from "./component/Resource/BankingTransaction/View/BankingTransactionEdit";
import SmsCreate from "./component/Resource/Sms/View/SmsCreate";
import SmsEdit from "./component/Resource/Sms/View/SmsEdit";
import SmsList from "./component/Resource/Sms/View/SmsList";
import SmsShow from "./component/Resource/Sms/View/SmsShow";
import SmsAllowedNumberCreate from "./component/Resource/SmsAllowedNumber/View/SmsAllowedNumberCreate";
import SmsAllowedNumberEdit from "./component/Resource/SmsAllowedNumber/View/SmsAllowedNumberEdit";
import SmsAllowedNumbersList from "./component/Resource/SmsAllowedNumber/View/SmsAllowedNumbersList";
import SmsAllowedNumberShow from "./component/Resource/SmsAllowedNumber/View/SmsAllowedNumberShow";
import { RecommendedTaskCreate } from "./component/Resource/RecommendedTask/View/RecommendedTaskCreate";
import { RecommendedTaskEdit } from "./component/Resource/RecommendedTask/View/RecommendedTaskEdit";
import { RecommendedTaskShow } from "./component/Resource/RecommendedTask/View/RecommendedTaskShow";
import { RecommendedTasksList } from "./component/Resource/RecommendedTask/View/RecommendedTasksList";
import { UserMonthlySubscriptionShow } from "./component/Resource/UserMonthlySubscription/View/UserMonthlySubscriptionShow";
import UserMonthlySubscriptionsList from "./component/Resource/UserMonthlySubscription/View/UserMonthlySubscriptionsList";
import { UserMonthlySubscriptionIncludingExpiredShow } from "./component/Resource/UserMonthlySubscriptionIncludingExpired/View/UserMonthlySubscriptionIncludingExpiredShow";
import UserMonthlySubscriptionsIncludingExpiredList from "./component/Resource/UserMonthlySubscriptionIncludingExpired/View/UserMonthlySubscriptionsIncludingExpiredList";
import SubscriptionTransactionsList from "./component/Resource/WalletTransactions/View/SubscriptionTransactionsList";

import DiscountCodeCreate from "./component/Resource/DiscountCode/View/DiscountCodeCreate";
import DiscountCodeEdit from "./component/Resource/DiscountCode/View/DiscountCodeEdit";
import { DiscountCodeShow } from "./component/Resource/DiscountCode/View/DiscountCodeShow";
import { DiscountCodesList } from "./component/Resource/DiscountCode/View/DiscountCodesList";
import { NotificationCreate } from "./component/Resource/Notification/View/NotificationCreate";
import { NotificationsList } from "./component/Resource/Notification/View/NotificationsList";
import { NotificationEdit } from "./component/Resource/Notification/View/NotificationEdit";
import { NotificationShow } from "./component/Resource/Notification/View/NotificationShow";
import TaxCreate from "./component/Resource/Tax/View/TaxCreate";
import TaxEdit from "./component/Resource/Tax/View/TaxEdit";
import TaxShow from "./component/Resource/Tax/View/TaxShow";
import TaxesList from "./component/Resource/Tax/View/TaxesList";
import SettingCreate from "./component/Resource/Setting/View/SettingCreate";
import SettingEdit from "./component/Resource/Setting/View/SettingEdit";
import SettingShow from "./component/Resource/Setting/View/SettingShow";
import SettingsList from "./component/Resource/Setting/View/SettingsList";
import EarlyAdopterCreate from './component/Resource/EarlyAdopter/View/EarlyAdopterCreate';
import EarlyAdopterEdit from './component/Resource/EarlyAdopter/View/EarlyAdopterEdit';
import EarlyAdopterList from './component/Resource/EarlyAdopter/View/EarlyAdopterList';
import EarlyAdopterShow from './component/Resource/EarlyAdopter/View/EarlyAdopterShow';
import { LocaleCreate } from "./component/Resource/Locale/LocaleCreate";
import { LocaleEdit } from "./component/Resource/Locale/LocaleEdit";
import { LocaleList } from "./component/Resource/Locale/LocaleList";
import { LocaleShow } from "./component/Resource/Locale/LocaleShow";

export default [
    {
        name: 'admins',
        icon: VerifiedUser,
        edit: AdminEdit,
        list: AdminsList,
        show: AdminShow,
        create: AdminCreate,
    },
    {
        name: 'kyc-answers',
        icon: Group,
        list: KycAnswerList,
    },
    {
        name: 'user-alertnesses',
        icon: Group,
        list: UserAlertnessList,
    },
    {
        name: 'parent-bank-accounts',
        icon: Group,
        list: BankAccountList,
        edit: AccountEdit,
    },
    {
        name: 'blocked-bank-accounts',
        icon: Group,
        list: BlockedBankAccountList,
        edit: AccountEdit,
    },
    {
        name: 'user-referrals',
        icon: Group,
        list: UserReferralList,
    },
    {
        name: 'feedback',
        icon: Group,
        list: FeedbackList,
        edit: FeedbackEdit
    },
    {
        name: 'users',
        icon: Group,
        list: UsersList,
        show: UserShow,
        edit: UserEdit,
    },
    {
        name: 'lesson-topics',
        icon: School,
        create: LessonTopicCreate,
        edit: LessonTopicEdit,
        show: LessonTopicShow,
        list: LessonTopicsList,
    },
    {
        name: 'lessons',
        icon: MenuBook,
        create: LessonCreate,
        edit: LessonEdit,
        show: LessonShow,
        list: LessonsList,
    },
    {
        name: 'lesson-materials',
        icon: LibraryBooks,
        create: LessonMaterialCreate,
        edit: LessonMaterialEdit,
        show: LessonMaterialShow,
        list: LessonMaterialsList,
    },
    {
        name: 'lesson-questions',
        icon: ImportContacts,
        create: LessonQuestionCreate,
        edit: LessonQuestionEdit,
        show: LessonQuestionShow,
        list: LessonQuestionsList,
    },
    {
        name: 'lesson-question-answers',
        icon: QuestionAnswer,
        create: LessonQuestionAnswerCreate,
        edit: LessonQuestionAnswerEdit,
        show: LessonQuestionAnswerShow,
        list: LessonQuestionAnswersList,
    },
    {
        name: 'lesson-test-attempts',
        icon: BorderColor,
        create: LessonTestAttemptCreate,
        edit: LessonTestAttemptEdit,
        show: LessonTestAttemptShow,
        list: LessonTestAttemptsList,
    },
    {
        name: 'lesson-progress-levels',
        icon: CallMade,
        create: LessonProgressLevelCreate,
        edit: LessonProgressLevelEdit,
        show: LessonProgressLevelShow,
        list: LessonProgressLevelsList,
    },
    {
        name: 'pages',
        icon: FontDownload,
        create: PageCreate,
        edit: PageEdit,
        show: PageShow,
        list: PagesList,
    },
    {
        name: 'faqs',
        icon: LiveHelp,
        create: FaqCreate,
        edit: FaqEdit,
        show: FaqShow,
        list: FaqsList,
    },
    {
        name: 'tips',
        icon: InsertComment,
        create: TipCreate,
        edit: TipEdit,
        show: TipShow,
        list: TipsList,
    },
    {
        name: 'tip-completions',
        icon: DoneAll,
        create: TipCompletionCreate,
        edit: TipCompletionEdit,
        show: TipCompletionShow,
        list: TipCompletionsList,
    },
    {
        name: 'tasks',
        icon: ListAlt,
        create: TaskCreate,
        edit: TaskEdit,
        show: TaskShow,
        list: TasksList,
    },
    {
        name: 'recommended-tasks',
        icon: AssignmentReturned,
        create: RecommendedTaskCreate,
        edit: RecommendedTaskEdit,
        show: RecommendedTaskShow,
        list: RecommendedTasksList,
    },
    {
        name: 'wallets',
        icon: AccountBalanceWallet,
        hidden: true
    },
    {
        name: 'addresses',
        hidden: true
    },
    {
        name: 'bank-clients',
        hidden: true
    },
    {
        name: 'goals',
        hidden: true
    },
    {
        name: 'deposits',
        hidden: true
    },
    {
        name: 'idle-wallets',
        hidden: true
    },
    {
        name: 'wallet-transactions',
        icon: AccountBalanceWallet,
        list: WalletTransactionsList,
        show: WalletTransactionShow,
    },
    {
        name: 'top-up-transactions',
        icon: MonetizationOn,
        list: TopUpTransactionsList,
        show: TopUpTransactionShow,
    },
    {
        name: "banking-transactions",
        icon: AccountBalance,
        list: BankingTransactionsList,
        show: BankingTransactionShow,
        edit: BankingTransactionEdit
    },
    {
        name: "sms",
        create: SmsCreate,
        edit: SmsEdit,
        list: SmsList,
        show: SmsShow,
    },
    {
        name: "sms-allowed-numbers",
        create: SmsAllowedNumberCreate,
        edit: SmsAllowedNumberEdit,
        list: SmsAllowedNumbersList,
        show: SmsAllowedNumberShow,
    },
    {
        name: "discount-codes",
        create: DiscountCodeCreate,
        edit: DiscountCodeEdit,
        show: DiscountCodeShow,
        list: DiscountCodesList,
    },
    {
        name: "user-monthly-subscriptions",
        list: UserMonthlySubscriptionsList,
        show: UserMonthlySubscriptionShow,
    },
    {
        name: "user-monthly-subscription-including-expired",
        list: UserMonthlySubscriptionsIncludingExpiredList,
        show: UserMonthlySubscriptionIncludingExpiredShow,
    },
    {
        name: "active-user-monthly-subscriptions",
        list: UserMonthlySubscriptionsIncludingExpiredList,
        show: UserMonthlySubscriptionIncludingExpiredShow,
    },
    {
        name: "subscription-transactions",
        list: SubscriptionTransactionsList,
    },
    {
        name: 'user-parent-invites',
        hidden: true
    },
    {
        name: 'bank-cards',
        hidden: true
    },
    {
        name: 'bank-card-applications',
        hidden: true
    },
    {
        name: "notifications",
        icon: NotificationsActive,
        create: NotificationCreate,
        list: NotificationsList,
        edit: NotificationEdit,
        show: NotificationShow,
    },
    {
        name: "taxes",
        create: TaxCreate,
        edit: TaxEdit,
        show: TaxShow,
        list: TaxesList,
    },
    {
        name: "settings",
        create: SettingCreate,
        edit: SettingEdit,
        show: SettingShow,
        list: SettingsList,
    },
    {
        name: 'system/currencies',
        hidden: true,
    },
    {
        name: 'system/intl/countries',
        hidden: true
    },
    {
        name: 'system/intl/currencies',
        hidden: true
    },
    {
        name: 'system/intl/languages',
        hidden: true
    },
    {
        name: 'system/intl/locales',
        create: LocaleCreate,
        edit: LocaleEdit,
        list: LocaleList,
        show: LocaleShow
    },
    {
        name: "early-adopters",
        create: EarlyAdopterCreate,
        edit: EarlyAdopterEdit,
        list: EarlyAdopterList,
        show: EarlyAdopterShow
    },
    {
        name: "registration-reasons",
        create: RegistrationReasonCreate,
        edit: RegistrationReasonEdit,
        list: RegistrationReasonList,
    }
];
