import React from "react";
import { Tab, ReferenceManyField } from 'react-admin';
import WalletCard from "./WalletCard";

const idleWallets = () => {
    return (
        <ReferenceManyField reference="idle-wallets" target="user">
            <WalletCard />
        </ReferenceManyField>
    );
}

const WalletsTab = (props) => {
    const { record: { isParent: isParent } } = props;
    return (
        <Tab label="Wallets" path="wallets" {...props}>
            <ReferenceManyField reference="wallets" target="user">
                <WalletCard/>
            </ReferenceManyField>
            { isParent ? null : idleWallets() }
        </Tab>
    )
}

export default WalletsTab;
