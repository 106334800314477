const locales = [
    {
        "code": "lt",
        "name": "Lietuvių",
        "disabled": false,
        "countryCode": "ltu",
        "isDefault": true,
    },
    {
        "code": "en",
        "name": "English",
        "disabled": false,
        "countryCode": "usa",
        "isDefault": false,
    },
    {
        "code": "ru",
        "name": "Русский",
        "disabled": false,
        "countryCode": "rus",
        "isDefault": false,
    },
    {
        "code": "pl",
        "name": "Polski",
        "disabled": false,
        "countryCode": "pol",
        "isDefault": false,
    }
];

export const availableLocales = locales.filter(l => !l.disabled);
export const availableLocalesCodes = availableLocales.map(l => l['code']);
export const defaultLocale = locales.find(l => !l.disabled && l.isDefault);
export const defaultLocaleCode = defaultLocale ? defaultLocale['code'] : 'lt';
