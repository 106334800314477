import React from "react";
import { Tab, ReferenceManyField } from 'react-admin';
import DepositCard from "./DepositCard";

const DepositsTab = (props) => (
    <Tab label="Deposits" path="deposits" {...props}>
        <ReferenceManyField reference="deposits" target="user">
            <DepositCard />
        </ReferenceManyField>
    </Tab>
)

export default DepositsTab;
