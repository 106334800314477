import React from "react";
import { Tab, ReferenceManyField } from 'react-admin';
import BankCardApplicationTabCard from "./BankCardApplicationTabCard";

const BankCardApplicationTab = (props) => {
    return (
        <Tab label="Card applications" path="bankCardApplications" {...props}>
            <ReferenceManyField reference="bank-card-applications" target="user">
                <BankCardApplicationTabCard/>
            </ReferenceManyField>
        </Tab>
    )
}

export default BankCardApplicationTab;
