import React from "react";
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import {Datagrid, EditButton,  ShowButton, TextField,} from 'react-admin';
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import useStyles from "../../../../styles";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export default (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete {...props}>
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow} />
                <CreatedAtField cellClassName={classes.narrow} />
                <UpdatedAtField cellClassName={classes.narrow} />
                <TextField source="phone" />
                <TextField source="comment" />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </ListConfirmDelete>
    );
};
