import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    PasswordInput,
    CheckboxGroupInput,
} from 'react-admin';
import { RoleChoices } from "../types";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_MODERATOR, ROLE_MODERATOR_INTERN } from "../../../../core/Types/roles";
import LastLoginField from "../../../Field/Common/LastLoginField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";

export const AdminEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect={false}>
            <TextInput source="name"/>
            <TextInput source="email"/>
            <PasswordInput source="password" />
            <CheckboxGroupInput
                label="Roles"
                source="roles"
                choices={RoleChoices}
                format={(v) => {
                    return Object.values(v).filter(s => s === ROLE_MODERATOR_INTERN || s === ROLE_MODERATOR || s === ROLE_ADMIN || s === ROLE_SUPER_ADMIN);
                }}
            />
            <CreatedAtField addLabel/>
            <UpdatedAtField addLabel/>
            <LastLoginField addLabel/>
        </SimpleForm>
    </Edit>
);
