import React from "react";
import {
    Datagrid,
    TextField,
    EditButton,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import useStyles from "../../../../styles";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";
import { Typography } from "@material-ui/core";
import UserField from "../../../Field/Common/UserField";
import { BankAccountIssues } from "../../../../core/Types/bankAccountIssues";

const IssueField = (props) => {
    return <Typography>[{props.record.issue}] { BankAccountIssues[props.record.issue] }</Typography>
}

export const BlockedBankAccountList = (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete
            {...props}
            sort={{ field: 'issue', order: 'ASC' }}
        >
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow} />
                <IssueField label="Issue" source="issue" cellClassName={classes.narrow} />
                <UserField label="User" usersource="user" namesource="userName" phonesource="userPhone" cellClassName={classes.narrow} />
                <TextField label="Email" source="userEmail" cellClassName={classes.narrow} />
                <TextField label="Name" source="userFirstName" cellClassName={classes.narrow} />
                <TextField label="Surname" source="userLastName" cellClassName={classes.narrow} />
                <TextField label="Title" source="title" cellClassName={classes.narrow} />
                <TextField label="IBAN" source="iban" />
                <EditButton />
            </Datagrid>
        </ListConfirmDelete>
    );
};
