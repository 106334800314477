export function getCurrentDateInAwsFormat () {
    let date = new Date();

    return [
        date.getFullYear(),
        '-',
        ('0' + (date.getMonth()+1)).substr(-2, 2),
        '-',
        ('0' + date.getDate()).substr(-2, 2),
        'T',
        ('0' + date.getHours()).substr(-2, 2),
        ':',
        ('0' + date.getMinutes()).substr(-2, 2),
        ':',
        ('0' + date.getSeconds()).substr(-2, 2),
        '+00:00'
    ].join('')
}
