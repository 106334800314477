import React from "react";
import {
    Datagrid,
    TextField,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import useStyles from "../../../../styles";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";
import UserField from "../../../Field/Common/UserField";

export const KycAnswerList = (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete
            {...props}
            sort={{ field: 'createdAt', order: 'DESC' }}
            create={false}
        >
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow} />
                <CreatedAtField cellClassName={classes.narrow} />
                <UserField label="User" cellClassName={classes.narrow} />
                <TextField label="Type" source="typeName" cellClassName={classes.narrow} />
                <TextField sortBy="step" label="Step" source="stepName" cellClassName={classes.narrow} />
                <TextField source="status" cellClassName={classes.narrow} />
                <TextField label="Name" source="name" cellClassName={classes.narrow} />
                <TextField label="Surname" source="surname" cellClassName={classes.narrow} />
                <TextField label="Request" source="request" cellClassName={classes.narrow} />
                <TextField label="Agent Answer" source="agentAnswer" cellClassName={classes.block250} />
                <TextField label="Last Error" source="lastError" cellClassName={classes.block250} />
            </Datagrid>
        </ListConfirmDelete>
    );
};
