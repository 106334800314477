import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    BooleanField
} from 'react-admin';
import CreatedAtField from "../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../Field/Common/UpdatedAtField";

export const LocaleShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="code" />
            <TextField source="name" />
            <TextField source="countryCode" />
            <BooleanField source="isDefault" />
            <BooleanField source="disabled" />
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
