import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_MODERATOR, ROLE_MODERATOR_INTERN } from "../../Types/roles";
import { decodeToken } from "../../../auth/utils";

export class SessionUser {
    name: string;
    roles: string[];
    expires: number = 0;

    constructor(name: string, roles: string[], expires = 0) {
        this.name = name;
        this.roles = roles;
        this.expires = expires;
    }

    hasRole(role: string): boolean {
        return this.roles.indexOf(role) !== -1;
    }

    isModeratorIntern(): boolean {
        return this.hasRole(ROLE_MODERATOR_INTERN) || this.isModerator();
    }

    isModerator(): boolean {
        return this.hasRole(ROLE_MODERATOR) || this.isAdmin();
    }

    isAdmin(): boolean {
        return this.hasRole(ROLE_ADMIN) || this.isSuperAdmin();
    }

    isSuperAdmin(): boolean {
        return this.hasRole(ROLE_SUPER_ADMIN);
    }

    static fromToken(token): SessionUser {
        const { uid, roles, exp } = decodeToken(token);
        return new SessionUser(uid, roles, exp);
    }
}
