import React from "react";
import { TextField } from 'react-admin';
import PhoneField from "../User/PhoneField";
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import Button from '@material-ui/core/Button';

const stopPropagation = e => e.stopPropagation();

const UserField = (props) => {
    let contents = {
        button: <></>,
        phone: <></>,
    }

    if (props.record[props.namesource]) {
        contents.button = <TextField {...props} source={props.namesource} />
    }

    if (props.record[props.usersource]) {
        contents.button = <Button
            component={Link}
            to={{ pathname: `${linkToRecord(`/users`, props.record[props.usersource])}` }}
            onClick={stopPropagation}>
            { contents.button }
        </Button>
    }

    if (props.record[props.phonesource]) {
        contents.phone = <div><PhoneField {...props} source={props.phonesource} /></div>
    }

    return <>{ contents.button }{ contents.phone }</>
}

UserField.defaultProps = {
    label: "User",
    usersource: "user",
    namesource: "userName",
    phonesource: "userPhone",
}

export default UserField;
