import React from "react";
import {
    Edit,
    NumberInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required,
} from 'react-admin';
import { Typography } from "@material-ui/core";
import { BankAccountIssues } from "../../../../core/Types/bankAccountIssues";

let issueOptions:any = [];

for (let bankAccountIssuesKey in BankAccountIssues) {
    issueOptions.push({
        id: parseInt(bankAccountIssuesKey),
        name: `[${bankAccountIssuesKey}] ${BankAccountIssues[bankAccountIssuesKey]}`,
    });
}

export default (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="title" disabled />
            <TextInput source="iban" disabled />
            <SelectInput source="issue" validate={required()} choices={issueOptions} />
        </SimpleForm>
    </Edit>
);
