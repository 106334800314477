import React from "react";
import { TextField } from 'react-admin';
import PhoneField from "../User/PhoneField";
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import Button from '@material-ui/core/Button';

const stopPropagation = e => e.stopPropagation();

const ParentField = (props) => {
    if (!props.record.parent) {
        return <div></div>
    }

    return <div>
        <Button
            component={Link}
            to={{ pathname: `${linkToRecord(`/users`, props.record.parent)}` }}
            onClick={stopPropagation}>
            <TextField {...props} source="parentName" />
        </Button>
        <div><PhoneField {...props} source="parentPhone" /></div>
    </div>
}

ParentField.defaultProps = { label: "Parent" }

export default ParentField;
