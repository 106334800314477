import React from "react";
import { Error, LinearProgress } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardActions, CardContent, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {formatApiUrl} from "../../../../../../auth/utils";
import {tokenStorage} from "../../../../../../auth/tokenStorage";
import {fetchUtils, useRefresh} from 'react-admin';
import { omitBy, isNil, last } from 'lodash';

const httpClient = fetchUtils.fetchJson;

const useStyles = makeStyles({
    addressCard: {
        width: '250px'
    },
    heading: {
        marginTop: '0.25em',
        marginLeft: '0.5em',
    },
    data: {
        fontSize: '9pt',
        marginTop: '0.25em',
        marginLeft: '0.5em',
    }
});

const getTypeLabel = (type: number): string => {
    switch (type) {
        case 1: return 'Savings'
        case 2: return 'Investments'
        default: return 'Undefined'
    }
}

const BankCardTabCard = (props) => {
    const { ids, error, data, loaded } = props;
    const classes = useStyles();
    const refresh = useRefresh();

    if (!loaded) return <LinearProgress />;
    if (error) return <Error error={error}/>;
    if (!data) return null;

    const deleteCard = (input) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            let id = props.record.originId;
            let options = {
                headers: new Headers({
                    'Authorization': `Bearer ${tokenStorage.getToken()}`,
                }),
                method: 'POST',
                body: JSON.stringify({})
            };

            const url = formatApiUrl('users/admin-delete-cards', id);

            httpClient(url, options)
                .then((data) => {
                    refresh();
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    const orderCard = (input) => {
        if (window.confirm('Are you sure you want to issue a new card?')) {
            let id = props.record.originId;
            let options = {
                headers: new Headers({
                    'Authorization': `Bearer ${tokenStorage.getToken()}`,
                }),
                method: 'POST',
                body: JSON.stringify({})
            };

            const url = formatApiUrl('users/admin-order-card', id);

            httpClient(url, options)
                .then((data) => {
                    refresh();
                    alert('Order finished');
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    const CreateButton = ids.length ? <></> : <CardActions>
        <Button size="small" variant="contained" onClick={orderCard}>
            Order
        </Button>
    </CardActions>

    return (
        <Grid container spacing={3}>
            {CreateButton}
            {ids.map(id =>
                <Grid item key={id}>
                    <Card variant="outlined" className={classes.addressCard}>
                        <CardActions>
                            <Typography className={classes.heading}>{data[id].key}</Typography>
                        </CardActions>
                        <CardActions>
                            <Typography color="textSecondary" className={classes.data}>Card Id:</Typography>
                            <Typography color="textPrimary" className={classes.data}>{data[id].cardId}</Typography>
                        </CardActions>
                        <CardActions>
                            <Typography color="textSecondary" className={classes.data}>Created:</Typography>
                            <Typography color="textPrimary" className={classes.data}>{data[id].created}</Typography>
                        </CardActions>
                        <CardActions>
                            <Typography color="textSecondary" className={classes.data}>Number (masked):</Typography>
                            <Typography color="textPrimary" className={classes.data}>{data[id].numberMasked}</Typography>
                        </CardActions>
                        <CardActions>
                            <Typography color="textSecondary" className={classes.data}>Status:</Typography>
                            <Typography color="textPrimary" className={classes.data}>{data[id].status}</Typography>
                        </CardActions>
                        <CardActions>
                            <Typography color="textSecondary" className={classes.data}>Contactless:</Typography>
                            <Typography color="textPrimary" className={classes.data}>{data[id].contactless ? 'yes' : 'no'}</Typography>
                        </CardActions>
                        <CardActions>
                            <Typography color="textSecondary" className={classes.data}>Enrolled:</Typography>
                            <Typography color="textPrimary" className={classes.data}>{data[id].enrolled ? 'yes' : 'no'}</Typography>
                        </CardActions>
                        <CardActions>
                            <Typography color="textSecondary" className={classes.data}>Expires (year / month):</Typography>
                            <Typography color="textPrimary" className={classes.data}>{data[id].expirationYear} / {data[id].expirationMonth}</Typography>
                        </CardActions>
                        <CardActions>
                            <Button size="small" variant="contained" onClick={deleteCard}>
                                Delete
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                )
            }
        </Grid>
    )
}

export default BankCardTabCard;
