import React from "react";
import { Tab, ReferenceManyField } from 'react-admin';
import AddressCard from "./AddressCard";

const AddressesTab = (props) => {
    return (
        <Tab label="Addresses" path="addresses" {...props}>
            <ReferenceManyField reference="addresses" target="user">
                <AddressCard/>
            </ReferenceManyField>
        </Tab>
    )
}

export default AddressesTab;
