import React from "react";
import config from "../../../config";
import { DateField } from 'react-admin';

const styles: React.CSSProperties = {
    whiteSpace: "nowrap"
}

class TimestampField extends React.Component<any>
{
    render() {
        return <DateField style={styles} locales={config.dateTimeLocale} showTime {...this.props} />
    }
}

export default TimestampField;
