import * as React from "react";
import { FieldProps } from "../../../core/Types/common";
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

interface Props extends FieldProps
{
    disabledStatus: number|number[]
}

const useStyles = makeStyles({
    disabled: {
        color: 'grey !important'
    },
    enabled: {
        color: 'inherit'
    },
});

const StatusAwareEditButton = (props: Props) => {
    const { record, disabledStatus } = props;
    const classes = useStyles();
    const disabled = record.status === disabledStatus ||
        (Array.isArray(disabledStatus) && disabledStatus.indexOf(record.status) >= 0)

    return (
        <EditButton
            {...props}
            disabled={disabled}
            className={disabled ? classes.disabled : classes.enabled}
        />
    );
};

export default StatusAwareEditButton;
