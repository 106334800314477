import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    minLength,
    maxLength
} from 'react-admin';

export const localeCodeValidation = [required(), minLength(2), maxLength(2)];
export const countryCodeValidation = [required(), minLength(3), maxLength(3)];

export const LocaleCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="code" validate={localeCodeValidation} />
            <TextInput source="name" validate={required()} />
            <TextInput source="countryCode" validate={countryCodeValidation} />
            <BooleanInput source="isDefault"/>
            <BooleanInput source="disabled"/>
        </SimpleForm>
    </Create>
);
