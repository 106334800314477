import React from "react";
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import {
    Datagrid,
    NumberField,
    SelectField,
    ShowButton,
    EditButton,
} from 'react-admin';
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import useStyles from "../../../../styles";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";
import {EuropeCountryChoices} from "../../../../core/Types/country";
import SettingCategories from "../categories";

export default (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete {...props}>
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow} />
                <CreatedAtField cellClassName={classes.narrow} />
                <UpdatedAtField cellClassName={classes.narrow} />
                <SelectField source="category" choices={SettingCategories} cellClassName={classes.narrow} />
                <SelectField source="country" choices={EuropeCountryChoices} />
                <NumberField label="Value" source="value" />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </ListConfirmDelete>
    );
};
