import React from "react";
import {
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    ReferenceManyField,
    SingleFieldList,
    ReferenceField
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import useStyles from "../../../../styles";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";
import UserField from "../../../Field/Common/UserField";
import MoneyAmountField from "../../../Field/Wallet/MoneyAmountField";

export const UserReferralList = (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete
            {...props}
            sort={{ field: 'createdAt', order: 'DESC' }}
            create={false}
        >
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow} />
                <CreatedAtField cellClassName={classes.narrow} />
                <UpdatedAtField cellClassName={classes.narrow} />
                <TextField label="Status" source="statusName" cellClassName={classes.narrow}/>
                <UserField label="Sender" usersource="sender" namesource="senderName" phonesource="senderPhone" />
                <UserField label="Receiver" usersource="receiver" namesource="receiverName" phonesource="receiverPhone" />
                <MoneyAmountField source="senderReward"/>
                <MoneyAmountField source="receiverReward"/>
                <EditButton />
            </Datagrid>
        </ListConfirmDelete>
    );
};
