import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import UsageStatsReportTable from '../ReportTable/UsageStatsReportTable';
import InvoiceExport from '../InvoiceExport/InvoiceExport';
import UserFlowExport from '../UserFlowExport/UserFlowExport';
import RegistrationReasonsExport from '../RegistrationReasonsExport/RegistrationReasonsExport';
import PushNotifications from '../PushNotifications/PushNotifications';

import { Title } from 'react-admin';

const blockStyle: React.CSSProperties = {float: 'left', marginRight: '20px'}

export default () => (
    <div>
        <Title title="Dashboard" />
        <Card>
            <CardContent>
                <div style={blockStyle}><UsageStatsReportTable /></div>
                <div style={blockStyle}><InvoiceExport /></div>
                <div style={blockStyle}><RegistrationReasonsExport /></div>
                <div style={blockStyle}><UserFlowExport /></div>
                <div style={blockStyle}><PushNotifications /></div>
            </CardContent>
        </Card>
    </div>
);
