import React from "react";
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import DiscountCodeTypeField from "../../../Field/DiscountCode/DiscountCodeTypeField";
import {Datagrid, EditButton, NumberField, ShowButton, TextField,} from 'react-admin';
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import useStyles from "../../../../styles";
import TimestampField from "../../../Field/Common/TimestampField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export const DiscountCodesList = (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete {...props}>
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow} />
                <CreatedAtField cellClassName={classes.narrow} />
                <UpdatedAtField cellClassName={classes.narrow} />
                <TextField source="title" />
                <TextField source="code" />
                <DiscountCodeTypeField component="text" addLabel />
                <NumberField source="discountSize" />
                <NumberField source="duration" />
                <TimestampField source="validityBegin" />
                <TimestampField source="validityEnd" />
                <NumberField label="Usages" source="userSubscriptionsCount" />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </ListConfirmDelete>
    );
};
