import { NullableString } from "../core/Types/common";

const SELECTED_LOCALE_KEY = "selected_locale";

export default {
    setSelectedLocale(locale: NullableString) {
        if (!locale) {
            window.sessionStorage.removeItem(SELECTED_LOCALE_KEY);
        } else {
            window.sessionStorage.setItem(SELECTED_LOCALE_KEY, locale);
        }
    },
    getSelectedLocale(): NullableString {
        return window.sessionStorage.getItem(SELECTED_LOCALE_KEY);
    },
    unsetSelectedLocale(): void {
        this.setSelectedLocale(null);
    },
};
