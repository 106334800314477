import React from "react";
import {
    Create,
    NumberInput,
    required,
    SimpleForm,
    TextInput,
    BooleanInput
} from "react-admin";

export const RegistrationReasonCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="title" validate={required()} />
            <NumberInput source="position" step={1} min={0} />
            <BooleanInput source="hasCustomText"/>
            <BooleanInput source="published"/>
        </SimpleForm>
    </Create>
);