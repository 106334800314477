import config from "./config";
import { buildApiHeaders } from "./auth/utils";
import {tokenStorage} from "./auth/tokenStorage";
import {Redirect, Route} from "react-router-dom";
import React from "react";
import {fetchHydra as baseFetchHydra, hydraDataProvider as baseHydraDataProvider } from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import {FIXED} from "./core/Types/DiscountCodeTypes";
import StorageManager from "./utils/StorageManager";

const entrypoint = config.api.entrypoint.root;
const fetchHeaders = buildApiHeaders();
const headers = new Headers(fetchHeaders);
const fetchHydra = (url: string, options = {}) => baseFetchHydra(url, {...options, headers});

const apiDocumentationParser = (entrypoint: string) => parseHydraDocumentation(entrypoint, {headers})
    .then(
        ({api}) => ({api}),
        (result) => {
            switch (result.status) {
                case 401:
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [
                            <Route path="/" render={() => {
                                return tokenStorage.hasToken() ? <Redirect to="/"/> : <Redirect to="/login"/>
                            }}/>
                        ],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    ).catch((error) => {
        console.log(error);
    });

const hydraDataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);
const imageResources = ['lesson-topics', 'lesson-progress-levels', 'tips'];
const priceResources = ['subscriptions', 'taxes'];
const userNonWriteProperties = ["childrenIncomeSettings", "avatar", "addressSettings", "childrenInfoSettings", "profileSettings"];

const dataProvider = {
    ...hydraDataProvider,
    getOne: (resource, params) => {
        if (priceResources.includes(resource) || resource === 'discount-codes') {
            return hydraDataProvider
                .getOne(resource, params)
                .then(response => {
                    if (response.data) {
                        if (response.data.price && priceResources.includes(resource)) {
                            response.data.price /= 100
                        } else if (response.data.discountSize && response.data.type === FIXED && resource === 'discount-codes') {
                            response.data.discountSize /= 100
                        }
                    }

                    return response
                })
        }

        return hydraDataProvider.getOne(resource, params)
    },
    getList: (resource, params) => {
        if (priceResources.includes(resource) || resource === 'discount-codes') {
            return hydraDataProvider
                .getList(resource, params)
                .then(response => {
                    if (response.data) {
                        for (let idx in response.data) {
                            if (response.data[idx]) {
                                if (response.data[idx].price && priceResources.includes(resource)) {
                                    response.data[idx].price /= 100
                                } else if (
                                    response.data[idx].discountSize &&
                                    response.data[idx].type === FIXED &&
                                    resource === 'discount-codes'
                                ) {
                                    response.data[idx].discountSize /= 100
                                }
                            }
                        }
                    }

                    return response
                })
        }

        return hydraDataProvider.getList(resource, params)
    },
    create: (resource, params) => {
        if (priceResources.includes(resource) && params.data.price) {
            params.data.price = Math.round(params.data.price * 100);
            return _createRequest(resource, params);
        }

        if (resource === 'discount-codes' && params.data.discountSize && params.data.type === FIXED) {
            params.data.discountSize = Math.round(params.data.discountSize * 100);
            return _createRequest(resource, params);
        }

        if (!imageResources.includes(resource) || !params.data.encodedImage) {
            return _createRequest(resource, params);
        }

        // Freshly dropped pictures are File objects and must be converted to base64 strings
        const newImage = (params.data.encodedImage.rawFile instanceof File) ? [params.data.encodedImage] : [];

        return Promise.all(newImage.map(convertFileToBase64))
            .then(transformedNewPictures => {
                return _createRequest(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        encodedImage: transformedNewPictures[0]
                    },
                })
            });
    },
    update: (resource, params) => {
        if (priceResources.includes(resource) && params.data.price) {
            params.data.price = Math.round(params.data.price * 100);
            return _updateRequest(resource, params);
        }

        if (resource === 'discount-codes' && params.data.discountSize && params.data.type === FIXED) {
            params.data.discountSize = Math.round(params.data.discountSize * 100);
            return _updateRequest(resource, params);
        }

        if (resource === 'users') {
            for (let i in userNonWriteProperties) {
                let prop = userNonWriteProperties[i]
                if (params.data.hasOwnProperty(prop)) {
                    delete params.data[prop]
                }
            }
            return _updateRequest(resource, params);
        }

        if (!imageResources.includes(resource) || !params.data.encodedImage) {
            return _updateRequest(resource, params);
        }

        const newImage = (params.data.encodedImage.rawFile instanceof File) ? [params.data.encodedImage] : [];

        return Promise.all(newImage.map(convertFileToBase64))
            .then(transformedNewPictures => {
                return _updateRequest(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        encodedImage: transformedNewPictures[0]
                    },
                })
            });
    },
};

const _postRequest = (resource, params, method) => {
    const url = params.data && params.data.originId
        ? `${entrypoint}/${resource}/${params.data.originId}`
        : `${entrypoint}/${resource}`;
    const body = JSON.stringify(params.data);
    const headers = new Headers({
        ...fetchHeaders,
        'Content-Type': 'application/json'
    });

    return fetch(url, { method, headers, body });
}

const _createRequest = (resource, params) => {
    StorageManager.unsetSelectedLocale();
    return _postRequest(resource, params, 'POST')
        .then(response => response.json())
        .then(data => {
            return hydraDataProvider.getOne(resource, { id: data['@id'] });
        });
}

const _updateRequest = (resource, params) => {
    return _postRequest(resource, params, 'PUT')
        .then(response => response.json())
        .then(data => ({ data }));
}

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

export default dataProvider;
