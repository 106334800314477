import React from "react";
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import BooleanChip from "../../Element/Chip/BooleanChip";

const stopPropagation = e => e.stopPropagation();

const RelativeUsersField = (props) => {
    const items = (items) => (
        <>
            {items.map(item => (
                <Link
                    to={{ pathname: `${linkToRecord(`/users`, '/api/users/'+item[props.idSource])}/show` }}
                    onClick={stopPropagation}
                ><BooleanChip value={true} labelTrue={ item[props.namesource] }/></Link>
            ))}
        </>
    )

    if (!props.record[props.source]) {
        console.log(props.record)
    }

    return props.record[props.source] ? items(props.record[props.source]): <></>
}

RelativeUsersField.defaultProps = {
    label: "Users",
    source: "relatedUsers",
    namesource: "name",
    phonesource: "phone",
    idSource: "id",
}

export default RelativeUsersField;
