// @ts-nocheck
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import TableFooter from '@material-ui/core/TableFooter';
import Typography from '@material-ui/core/Typography';
import { fetchUtils } from 'react-admin';
import React, { useState, useRef } from 'react';
import { formatApiUrl } from "../../../../auth/utils";
import { tokenStorage } from "../../../../auth/tokenStorage";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

let segmentOptions = [{ value: '', label: ' - '}]
let segmentOptionsLoaded = false

fetchUtils.fetchJson(formatApiUrl('segments'), {
    headers : new Headers({
        'Authorization': `Bearer ${tokenStorage.getToken()}`,
    }),
    method: 'GET'
})
    .then((data) => {
        for (let i in data.json) {
            segmentOptions.push({ value: i, label: data.json[i]})
        }

        segmentOptionsLoaded = true
    }, () => {})

export default () => {
    const httpClient = fetchUtils.fetchJson;
    const [fetching, setFetching] = useState(false);
    const inputMessageRef = useRef(null);
    const inputPushTitleRef = useRef(null);
    const inputPushBodyRef = useRef(null);
    const inputPhonesRef = useRef(null);
    const inputUrlRef = useRef(null);
    const inputTypeRef = useRef(null);
    const inputSegmentRef = useRef(null);

    const submit = (receiverPhones = '', message = '', pushTitle = '', pushBody = '', url = '', type = '', segment = '') => {
        const options = {
            headers : new Headers({
                'Authorization': `Bearer ${tokenStorage.getToken()}`,
            }),
            method: 'POST',
            body: JSON.stringify({ receiverPhones, message, pushTitle, pushBody, type, url, segment })
        };

        setFetching(true);
        httpClient(formatApiUrl('push-notification'), options)
            .then((data) => {
                setFetching(false)
                alert(data.json.report['hydra:member'].join(";\r\n"));
            })
            .catch(error => {
                setFetching(false)
                console.log([error, error.body]);

                if (error.body && error.body["hydra:description"]) {
                    alert(error.body["hydra:description"])
                }
            })
    };

    const handleClick = () => {
        // @ts-ignore
        submit(
            inputPhonesRef.current && inputPhonesRef.current.value || "",
            inputMessageRef.current && inputMessageRef.current.value || "",
            inputPushTitleRef.current && inputPushTitleRef.current.value || "",
            inputPushBodyRef.current && inputPushBodyRef.current.value || "",
            inputUrlRef.current && inputUrlRef.current.value || "",
            inputTypeRef.current && inputTypeRef.current.value || "",
            inputSegmentRef.current && inputSegmentRef.current.value || ""
        );
    }

    return (
        <Box width={820}>
            <TableContainer>
                <Toolbar>
                    <Typography variant="h6">Push Notifications</Typography>
                </Toolbar>
                <Table size="small">
                    <TableFooter>
                        <TableRow>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 45, color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}>
                                    Segment
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 700 }}>
                                    {segmentOptionsLoaded && <Select name="inputSegment" fullWidth inputRef={inputSegmentRef} defaultValue="">
                                        {segmentOptions.map(option => (
                                            <MenuItem value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </Select>}
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 45, color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}>
                                    Phones
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 700 }}>
                                    <Input name="inputPhones" type="text" fullWidth inputRef={inputPhonesRef}  defaultValue={""}/>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 45, color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}>
                                    Text
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 700 }}>
                                    <Input name="inputMessage" type="text" fullWidth inputRef={inputMessageRef} defaultValue={""}/>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 45, color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}>
                                    Push Title
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 700 }}>
                                    <Input name="inputPushTitle" type="text" fullWidth inputRef={inputPushTitleRef} defaultValue={""}/>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 45, color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}>
                                    Push Body
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 700 }}>
                                    <Input name="inputPushBody" type="text" fullWidth inputRef={inputPushBodyRef} defaultValue={""}/>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 45, color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}>
                                    Url
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 700 }}>
                                    <Input name="inputUrl" type="text" fullWidth inputRef={inputUrlRef} defaultValue={""}/>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 45, color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}>
                                    Type
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 700 }}>
                                    <Select name="inputType" fullWidth inputRef={inputTypeRef} defaultValue={1}>
                                        <MenuItem value={0}>Sms</MenuItem>
                                        <MenuItem value={1}>Notification</MenuItem>
                                        <MenuItem value={2}>Notification (popup)</MenuItem>
                                        <MenuItem value={3}>Notification (url)</MenuItem>
                                    </Select>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Box style={{ display: 'flex', width: 700 }}>
                                    <Button size="small" variant="contained" onClick={handleClick}>
                                        Send
                                    </Button>
                                    <div style={{width: 50, marginLeft: 5}}>
                                        {fetching && <CircularProgress size={22}/>}
                                    </div>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
}
