import React from "react";
import {
  ReferenceField,
  TextField,
  Tab,
  Datagrid,
  ReferenceArrayField,
  ShowButton,
  BooleanField
} from 'react-admin';
import UserTypeField from "../../../../../Field/User/UserTypeField";
import CreatedAtField from "../../../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../../../Field/Common/UpdatedAtField";
import { FieldProps } from "../../../../../../core/Types/common";
import UserActiveStatusField from "../../../../../Field/User/UserActiveStatusField";
import ResourceIdField from "../../../../../Field/Common/ResourceIdField";
import useStyles from "../../../../../../styles";

const MainInfoTab = (props: FieldProps) => {
    const classes = useStyles();

    if (props.record) {
      props.record.parents = [props.record.parent]
    }

    return (
        <Tab label="Main info" path="main" {...props}>
            <TextField source="name"/>
            <TextField source="phone"/>
            <TextField source="adminComment"/>
            <TextField source="email"/>
            <TextField source="bankClientId"/>
            <TextField source="firstName"/>
            <TextField source="lastName"/>
            <TextField source="personalCode"/>
            <TextField source="alertness"/>
            <UserTypeField component="text" addLabel/>
            {props.record && !props.record.isParent &&
              <ReferenceField source="lessonProgressLevel" reference="lesson-progress-levels">
                  <TextField source="title" />
              </ReferenceField>
            }
            {props.record && !props.record.isParent && <TextField source="age" />}
            <UserActiveStatusField addLabel/>
            {props.record && props.record.isParent && (
              <ReferenceArrayField reference="users" record={props.record} source="children">
                  <Datagrid>
                      <ResourceIdField cellClassName={classes.narrow}/>
                      <CreatedAtField cellClassName={classes.narrow}/>
                      <TextField source="name"/>
                      <TextField source="phone"/>
                      <ShowButton />
                  </Datagrid>
              </ReferenceArrayField>
            )}
            {props.record && !props.record.isParent && (
              <ReferenceArrayField label="Parent" reference="users" record={props.record} source="parents">
                  <Datagrid>
                      <ResourceIdField cellClassName={classes.narrow}/>
                      <CreatedAtField cellClassName={classes.narrow}/>
                      <TextField source="name"/>
                      <TextField source="phone"/>
                      <ShowButton />
                  </Datagrid>
              </ReferenceArrayField>
            )}
            <BooleanField source="kyc" />
            <ReferenceArrayField reference="kyc-answers" source="kycAnswers">
              <Datagrid>
                <ResourceIdField cellClassName={classes.narrow}/>
                <CreatedAtField cellClassName={classes.narrow}/>
                <TextField source="name"/>
                <TextField source="surname"/>
                <TextField source="birthday"/>
                <TextField source="personcode"/>
                <TextField source="documentNumber"/>
                <TextField source="validTill"/>
              </Datagrid>
            </ReferenceArrayField>
            <CreatedAtField addLabel/>
            <UpdatedAtField addLabel/>
        </Tab>
    );
}

export default MainInfoTab;
