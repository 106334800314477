import React from "react";
import { Tab, ReferenceManyField } from 'react-admin';
import GoalCard from "./GoalCard";

const GoalsTab = (props) => (
    <Tab label="Goals" path="goals" {...props}>
        <ReferenceManyField reference="goals" target="user">
            <GoalCard />
        </ReferenceManyField>
    </Tab>
)

export default GoalsTab;
