import React from "react";
import {
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import UserTypeField from "../../../Field/User/UserTypeField";
import PhoneField from "../../../Field/User/PhoneField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import useStyles from "../../../../styles";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";
import RelativeUsersField from "../../../Field/Common/RelativeUsersField";

export const UsersList = (props) => {
    const classes = useStyles();

    return (
        <ListConfirmDelete sort={{ field: 'createdAt', order: 'DESC' }} {...props} create={false}>
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow}/>
                <CreatedAtField cellClassName={classes.narrow}/>
                <TextField label="Nickname" source="name" cellClassName={classes.narrow}/>
                <TextField label="Email" source="email" cellClassName={classes.narrow}/>
                <TextField label="Name"  source="firstName" cellClassName={classes.narrow}/>
                <TextField label="Surname" source="lastName" cellClassName={classes.narrow}/>
                <PhoneField cellClassName={classes.narrow}/>
                <RelativeUsersField label="Related Users" source="relatedUsers" />
                <UserTypeField cellClassName={classes.narrow}/>
                <TextField label="Subscription" source="subscriptionStatusText" cellClassName={classes.narrow}/>
                <TextField label="Comment" source="adminComment" cellClassName={classes.ellipsis200}/>
                <ShowButton />
                <EditButton />
            </Datagrid>
        </ListConfirmDelete>
    );
};
