import React from "react";
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import ImageEye from '@material-ui/icons/RemoveRedEye';

const stopPropagation = e => e.stopPropagation();

const SubscriptionLinkField = (props) => {
    //@TODO: link formatting
    return props.record.userMonthlySubscription ? (
        <Button
            component={Link}
            to={{ pathname: `${linkToRecord(`/user-monthly-subscriptions`, props.record.userMonthlySubscription)}/show` }}
            onClick={stopPropagation}
        ><ImageEye /></Button>
    ) : null
}

SubscriptionLinkField.defaultProps = { label: "Subscription" }

export default SubscriptionLinkField;