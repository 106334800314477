import React from "react";
import {
    Edit,
    NumberInput,
    required,
    SimpleForm,
    BooleanInput,
    TextInput
} from "react-admin";

export const RegistrationReasonEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="title" validate={required()} />
                <NumberInput source="position" step={1} min={0} />
                <BooleanInput source="hasCustomText"/>
                <BooleanInput source="published"/>
            </SimpleForm>
        </Edit>
    );
}
