import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
} from 'react-admin';
import { localeCodeValidation, countryCodeValidation } from "./LocaleCreate";

export const LocaleEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="code" validate={localeCodeValidation} />
            <TextInput source="name" validate={required()} />
            <TextInput source="countryCode" validate={countryCodeValidation} />
            <BooleanInput source="isDefault"/>
            <BooleanInput source="disabled"/>
        </SimpleForm>
    </Edit>
);
