import React from "react";
import {
    Datagrid,
    TextField,
    BooleanField
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import useStyles from "../../../../styles";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";
import UserField from "../../../Field/Common/UserField";
import { UserAlertnessTypes } from "../../../../core/Types/userAlertnessTypes";
import Typography from '@material-ui/core/Typography';

const TypeField = (props) => {
    return <Typography>[{props.record.type}] { UserAlertnessTypes[props.record.type] }</Typography>
}

export const UserAlertnessList = (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete
            {...props}
            sort={{ field: 'createdAt', order: 'DESC' }}
            create={false}
        >
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow} />
                <CreatedAtField cellClassName={classes.narrow} />
                <UserField label="User" cellClassName={classes.narrow} />
                <TypeField label="Type" source="type" cellClassName={classes.narrow} />
                <TextField label="Weight" source="weight" cellClassName={classes.narrow} />
                <TextField label="Text" source="text" cellClassName={classes.narrow} />
                <BooleanField label="False Alarm" source="isFalseAlarm" />
            </Datagrid>
        </ListConfirmDelete>
    );
};
