import React from "react";
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import MoneyAmountField from "../../../Field/Wallet/MoneyAmountField";
import TransactionStatusField from "../../../Field/Wallet/TransactionStatusField";
import WalletTransactionTypeField from "../../../Field/Wallet/WalletTransactionTypeField";
import useStyles from "../../../../styles";
import SenderNameAndPhoneField from "../../../Field/Wallet/SenderNameAndPhoneField";
import BeneficiaryNameAndPhoneField from "../../../Field/Wallet/BeneficiaryNameAndPhoneField";
import CommentField from "../../../Field/Wallet/CommentField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export const WalletTransactionsList = (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete {...props} sort={{ field: 'createdAt', order: 'desc' }}>
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow}/>
                <CreatedAtField cellClassName={classes.narrow}/>
                <CreatedAtField source="transferredAt" label="Transferred&nbsp;at" cellClassName={classes.narrow}/>
                <TextField source="uuid" cellClassName={classes.narrow}/>
                <MoneyAmountField cellClassName={classes.narrow}/>
                <SenderNameAndPhoneField cellClassName={classes.narrow}/>
                <BeneficiaryNameAndPhoneField cellClassName={classes.narrow}/>
                <CommentField />
                <WalletTransactionTypeField cellClassName={classes.narrow}/>
                <TransactionStatusField cellClassName={classes.narrow}/>
                <ShowButton />
            </Datagrid>
        </ListConfirmDelete>
    );
};
