import React from "react";
import { TabbedShowLayout } from 'react-admin';
import MainInfoTab from "./MainInfo/MainInfoTab";
import AssociationsTab from "./Associations/AssociationsTab";
import WalletsTab from "./Wallets/WalletsTab";
import GoalsTab from "./Goals/GoalsTab";
import DepositsTab from "./Deposits/DepositsTab";
import TasksTab from "./Tasks/TasksTab";
import TransactionsTab from "./Transactions/TransactionsTab";
import SubscriptionTab from "./Subscription/SubscriptionTab";
import AddressesTab from "./Addresses/AddressesTab";
import BankCardTab from "./BankCards/BankCardTab";
import BankCardApplicationTab from "./BankCardApplications/BankCardApplicationTab";

const UserShowTabsLayout = (props) => (
    <TabbedShowLayout {...props}>
        <MainInfoTab />
        {/*
        <AssociationsTab record={props.record} />
        <WalletsTab record={props.record} />
        <TasksTab record={props.record} />
        <TransactionsTab />
        <SubscriptionTab record={props.record}/>
        */}
        <WalletsTab record={props.record} />
        { !props.record.isParent && <DepositsTab /> }
        { !props.record.isParent && <GoalsTab /> }
        { props.record.isParent && <AddressesTab /> }
        { !props.record.isParent && <BankCardTab record={props.record} /> }
        { !props.record.isParent && <BankCardApplicationTab /> }
    </TabbedShowLayout>
)

export default UserShowTabsLayout;
