import React from "react";
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import {
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
} from 'react-admin';
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import LastLoginField from "../../../Field/Common/LastLoginField";
import useStyles from "../../../../styles";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";
import {userStorage} from "../../../../core/Model/SessionUser/userStorage";

let isSuperAdmin = userStorage.getUser()?.isSuperAdmin();

export const AdminsList = (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete {...props} create={isSuperAdmin}>
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow}/>
                <CreatedAtField cellClassName={classes.narrow}/>
                <UpdatedAtField cellClassName={classes.narrow}/>
                <TextField source="name" cellClassName={classes.narrow}/>
                <TextField source="email" cellClassName={classes.narrow}/>
                <LastLoginField/>
                { isSuperAdmin && <ShowButton /> }
                { isSuperAdmin && <EditButton /> }
            </Datagrid>
        </ListConfirmDelete>
    );
};
