import React from "react";
import {
    Datagrid,
    TextField,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import MoneyAmountField from "../../../Field/Wallet/MoneyAmountField";
import TransactionStatusField from "../../../Field/Wallet/TransactionStatusField";
import useStyles from "../../../../styles";
import SenderNameAndPhoneField from "../../../Field/Wallet/SenderNameAndPhoneField";
import SubscriptionLinkField from "../../../Field/Wallet/SubscriptionLinkField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export default (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete {...props} sort={{ field: 'createdAt', order: 'desc' }} create={false}>
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow}/>
                <CreatedAtField cellClassName={classes.narrow}/>
                <CreatedAtField source="transferredAt" label="Transferred&nbsp;at" cellClassName={classes.narrow}/>
                <MoneyAmountField cellClassName={classes.narrow}/>
                <SenderNameAndPhoneField cellClassName={classes.narrow}/>
                <SubscriptionLinkField cellClassName={classes.narrow}/>
                <TransactionStatusField cellClassName={classes.narrow}/>
                <TextField source="uuid"/>
            </Datagrid>
        </ListConfirmDelete>
    );
};
