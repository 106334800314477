import React from "react";
import {
    Datagrid,
    ReferenceField,
    TextField,
    Show,
    SimpleShowLayout,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import useStyles from "../../../../styles";
import MoneyAmountField from "../../../Field/Wallet/MoneyAmountField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";
import YesNoField from "../../../Field/Common/YesNoField";
import SubscriptionExpireStatusField from "../../../Field/UserSubscription/SubscriptionExpireStatusField";
import TransactionStatusField from "../../../Field/Wallet/TransactionStatusField";
import SenderNameField from "../../../Field/Wallet/SenderNameField";
import BeneficiaryNameField from "../../../Field/Wallet/BeneficiaryNameField";
import CommentField from "../../../Field/Wallet/CommentField";
import WalletTransactionTypeField from "../../../Field/Wallet/WalletTransactionTypeField";
import UuidField from "../../../Field/Wallet/UuidField";

export const UserMonthlySubscriptionShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ResourceIdField addLabel />
            <ReferenceField addLabel label="User" source="user" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <CreatedAtField addLabel />
            <SubscriptionExpireStatusField addLabel source="subscriptionExpiry"/>
            <YesNoField addLabel source="autoSubscribe"/>
            <MoneyAmountField addLabel source="priceWithoutDiscount"/>
            <MoneyAmountField addLabel source="priceWithDiscount"/>
            <TextField addLabel source="discountCodePlain" />
            <SubscriptionExpireStatusField addLabel source="discountExpiry"/>
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
