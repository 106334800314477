import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import TableFooter from '@material-ui/core/TableFooter';
import Typography from '@material-ui/core/Typography';
import { fetchUtils } from 'react-admin';
import React, { useState, useRef } from 'react';
import { formatApiUrl } from "../../../../auth/utils";
import { omitBy, isNil } from 'lodash';
import { tokenStorage } from "../../../../auth/tokenStorage";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

export default () => {
    const httpClient = fetchUtils.fetchJson;
    const [fetching, setFetching] = useState(false);
    const filterToRef = useRef(null);
    const filterFromRef = useRef(null);
    const filterRef = useRef(null);
    const defaultFilterFrom = moment().subtract(1, 'months').format('YYYY-MM-DD')
    const defaultFilterTo = moment().format('YYYY-MM-DD')

    const exportData = (filterFrom = null, filterTo = null, filter = null) => {
        const urlParams = omitBy({ filterFrom, filterTo, filter }, isNil);
        const url = formatApiUrl('stats/export-weekly-user-flow-stats', null, urlParams);

        const headers = new Headers({
            'Authorization': `Bearer ${tokenStorage.getToken()}`,
        });

        setFetching(true);
        httpClient(url, { headers })
            .then((data) => {
                setFetching(false)
                const filename = 'userFlow.csv'
                const blob = new Blob([data.body], {type: 'text/csv'})

                if (typeof window.navigator.msSaveOrOpenBlob === 'function') {
                    window.navigator.msSaveBlob(blob, filename)
                } else{
                    const elem = window.document.createElement('a')
                    elem.href = window.URL.createObjectURL(blob)
                    elem.download = filename
                    document.body.appendChild(elem)
                    elem.click()
                    document.body.removeChild(elem)
                }
            })
            .catch(error => {
                setFetching(false)
                console.log(error)
            })
    };

    const handleClick = () => {
        // @ts-ignore
        exportData(filterFromRef.current.value || null, filterToRef.current.value || null, filterRef.current.value || null);
    }

    return (
        <Box width={400}>
            <TableContainer>
                <Toolbar>
                    <Typography variant="h6">User Flow Export</Typography>
                </Toolbar>
                <Table size="small">
                    <TableFooter>
                        <TableRow>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 25, color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}>
                                    From
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 300 }}>
                                    <Input name="filterFrom" type="date" fullWidth inputRef={filterFromRef} defaultValue={defaultFilterFrom}/>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 25, color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}>
                                    To
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 300 }}>
                                    <Input name="filterTo" type="date" fullWidth inputRef={filterToRef} defaultValue={defaultFilterTo}/>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 25, color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}>
                                    Filter
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box style={{ display: 'flex', width: 300 }}>
                                    <Select name="filter" fullWidth inputRef={filterRef} defaultValue={null}>
                                        <MenuItem> - </MenuItem>
                                        <MenuItem value={"either"}>Either initiated connection</MenuItem>
                                        <MenuItem value={"neither"}>Nobody initiated connection</MenuItem>
                                        <MenuItem value={"child"}>Child initiated connection</MenuItem>
                                        <MenuItem value={"parent"}>Parent initiated connection</MenuItem>
                                    </Select>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Box style={{ display: 'flex', width: 300 }}>
                                    <Button size="small" variant="contained" onClick={handleClick}>
                                        Export
                                    </Button>
                                    <div style={{width: 30, marginLeft: 5}}>
                                        {fetching && <CircularProgress size={22}/>}
                                    </div>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
}
