export default [
    { id: 'Card manufacture', name: 'Card manufacture' },
    { id: 'Card shipping', name: 'Card shipping' },
    { id: 'Lost card replacement', name: 'Lost card replacement' },
    { id: 'Transaction fee for Premium user', name: 'Transaction fee for Premium user' },
    { id: 'Transaction fee for free user', name: 'Transaction fee for free user' },
    { id: 'Top-up balance to alert', name: 'Top-up balance to alert' },
    { id: 'Top-up balance to stop auto top-up', name: 'Top-up balance to stop auto top-up' },
    { id: 'Top-up limit transaction', name: 'Top-up limit transaction' },
    { id: 'Top-up limit daily', name: 'Top-up limit daily' },
    { id: 'Top-up limit monthly', name: 'Top-up limit monthly' },
    { id: 'Subscription price', name: 'Subscription price' },
    { id: 'Referral reward for sender', name: 'Referral reward for sender' },
    { id: 'Referral reward for receiver', name: 'Referral reward for receiver' },
    { id: 'Monthly inflow limit per user', name: 'Monthly inflow limit per user' },
    { id: 'Monthly inflow limit per family', name: 'Monthly inflow limit per family' },
    { id: 'Reward for first transaction', name: 'Reward for first transaction' },
];