import React from "react";
import { Error, LinearProgress } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardActions, CardContent, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles({
    addressCard: {
        width: '250px'
    },
    heading: {
        marginTop: '0.25em',
        marginLeft: '0.5em',
    },
    data: {
        fontSize: '9pt',
        marginTop: '0.25em',
        marginLeft: '0.5em',
    }
});

const getTypeLabel = (type: number): string => {
    switch (type) {
        case 1: return 'Savings'
        case 2: return 'Investments'
        default: return 'Undefined'
    }
}

const AddressCard = (props) => {
    const { ids, error, data, loaded } = props;
    const classes = useStyles();

    if (!loaded) return <LinearProgress />;
    if (error) return <Error error={error}/>;
    if (!data) return null;

    return (
        <Grid container spacing={3}>
            {ids.map(id =>
                <Grid item key={id}>
                    <Card variant="outlined" className={classes.addressCard}>
                        <CardActions>
                            <Typography className={classes.heading}>{data[id].key}</Typography>
                        </CardActions>
                        <CardActions>
                            <Typography color="textSecondary" className={classes.data}>Address:</Typography>
                            <Typography color="textPrimary" className={classes.data}>{data[id].address}</Typography>
                        </CardActions>
                        <CardActions>
                            <Typography color="textSecondary" className={classes.data}>City:</Typography>
                            <Typography color="textPrimary" className={classes.data}>{data[id].city}</Typography>
                        </CardActions>
                        <CardActions>
                            <Typography color="textSecondary" className={classes.data}>Postal Code:</Typography>
                            <Typography color="textPrimary" className={classes.data}>{data[id].postalCode}</Typography>
                        </CardActions>
                        <CardActions>
                            <Typography color="textSecondary" className={classes.data}>Country Code:</Typography>
                            <Typography color="textPrimary" className={classes.data}>{data[id].countryCode}</Typography>
                        </CardActions>
                    </Card>
                </Grid>
                )
            }
        </Grid>
    )
}

export default AddressCard;
