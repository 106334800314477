import React from "react";
import { Tab, ReferenceManyField } from 'react-admin';
import BankCardTabCard from "./BankCardTabCard";

const BankCardTab = (props) => {
    return (
        <Tab label="Bank Cards" path="BankCards" {...props}>
            <ReferenceManyField reference="bank-cards" target="user">
                <BankCardTabCard record={props.record}/>
            </ReferenceManyField>
        </Tab>
    )
}

export default BankCardTab;
