import React from "react";
import {
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    BooleanField
} from 'react-admin';
import CreatedAtField from "../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../Field/Common/UpdatedAtField";
import ListConfirmDelete from "../../Element/List/ListConfirmDelete";

export const LocaleList = (props) => {
    return (
        <ListConfirmDelete {...props}>
            <Datagrid>
                <CreatedAtField />
                <UpdatedAtField />
                <TextField source="code" />
                <TextField source="name" />
                <TextField source="countryCode" />
                <BooleanField source="isDefault" />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </ListConfirmDelete>
    );
};
