import React, {Fragment} from "react";
import {TopToolbar, CreateButton, ExportButton, BulkDeleteButton, Pagination, BulkExportButton, List, TextInput} from 'react-admin';
import {userStorage} from "../../../core/Model/SessionUser/userStorage";
let isAdmin = userStorage.getUser()?.isAdmin();

const PostBulkActionButtons = props => (
    <Fragment>
        {props.export && ( <BulkExportButton {...props} /> )}
        {props.delete && ( <BulkDeleteButton {...props} undoable={false}/> )}
    </Fragment>
);
const PostPagination = props => (
    <Pagination rowsPerPageOptions={[25, 100, 250]} {...props} />
)
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />
];

const ListConfirmDelete = (props) => (
    <List
        {...props}
        filters={postFilters}
        bulkActionButtons={<PostBulkActionButtons />}
        pagination={<PostPagination />}
        perPage={25}
        actions={<TopToolbar>
            {props.create && ( <CreateButton /> )}
            {props.export && ( <ExportButton /> )}
        </TopToolbar>}
    />
);

ListConfirmDelete.defaultProps = {
    create: true,
    export: true,
    delete: isAdmin,
}

export default ListConfirmDelete;
