import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import TableFooter from '@material-ui/core/TableFooter';
import Typography from '@material-ui/core/Typography';
import { fetchUtils } from 'react-admin';
import React, { useState, useEffect, useRef } from 'react';
import { formatApiUrl } from "../../../../auth/utils";
import { omitBy, isNil } from 'lodash';
import { tokenStorage } from "../../../../auth/tokenStorage";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

interface IUsageStatsData {
    registeredUsers: number,
    registeredChildren: number,
    registeredParents: number,
    parentChildrenConnections: number,
    kyc: number,
    subscriptions: number,
    activeUsers: number,
    referralsSent: number,
    referralsSubscribed: number,
}

const StatsTableRow = (props) => (
    <TableRow>
        <TableCell>
            <Typography>{props.title}</Typography>
        </TableCell>
        <TableCell align="right">
            <Typography color="secondary">{props.value}</Typography>
        </TableCell>
    </TableRow>
);

export default () => {
    const httpClient = fetchUtils.fetchJson;
    const [fetchingInvestors, setFetchingInvestors] = useState(false);
    const [fetchingMarketing, setFetchingMarketing] = useState(false);
    const [stats, setStats] = useState<IUsageStatsData | null>(null);
    const filterForInvestorsFromRef = useRef(null);
    const filterForInvestorsToRef = useRef(null);
    const filterForMarketingFromRef = useRef(null);
    const filterForMarketingToRef = useRef(null);
    const defaultFilterFrom = moment().subtract(1, 'months').format('YYYY-MM-DD')
    const defaultFilterTo = moment().format('YYYY-MM-DD')

    const {
        registeredUsers,
        registeredChildren,
        registeredParents,
        parentChildrenConnections,
        kyc,
        subscriptions,
        activeUsers,
        referralsSent,
        referralsSubscribed
    } = stats ?? {};

    const fetchStatsData = (filterFrom = null, filterTo = null) => {
        const urlParams = omitBy({ filterFrom, filterTo }, isNil);
        const url = formatApiUrl('stats/usage-stats', null, urlParams);
        const headers = new Headers({
            'accept': 'application/json',
            'Authorization': `Bearer ${tokenStorage.getToken()}`,
        });

        httpClient(url, { headers })
            .then(({ json }) => {
                setStats(json);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const setFetching = (status, stats) =>
    {
        switch (stats) {
            case 'investors':
                setFetchingInvestors(status)

                break;
            case 'marketing':
                setFetchingMarketing(status)

                break;
        }
    }

    const exportData = (filterFrom = null, filterTo = null, stats = null) => {
        const urlParams = omitBy({ filterFrom, filterTo, stats }, isNil);
        const url = formatApiUrl('stats/export-usage-stats', null, urlParams);

        const headers = new Headers({
            'Authorization': `Bearer ${tokenStorage.getToken()}`,
        });

        setFetching(true, stats);
        httpClient(url, { headers })
            .then((data) => {
                setFetching(false, stats)
                const filename = 'usage.csv'
                const blob = new Blob([data.body], {type: 'text/csv'})

                if (typeof window.navigator.msSaveOrOpenBlob === 'function') {
                    window.navigator.msSaveBlob(blob, filename)
                } else{
                    const elem = window.document.createElement('a')
                    elem.href = window.URL.createObjectURL(blob)
                    elem.download = filename
                    document.body.appendChild(elem)
                    elem.click()
                    document.body.removeChild(elem)
                }
            })
            .catch(error => {
                setFetching(false, stats)
                console.log(error)
            })
    };

    const exportForInvestors = () => {
        // @ts-ignore
        exportData(filterForInvestorsFromRef.current.value || null, filterForInvestorsToRef.current.value || null, 'investors');
    }

    const exportForMarketing = () => {
        // @ts-ignore
        exportData(filterForMarketingFromRef.current.value || null, filterForMarketingToRef.current.value || null, 'marketing');
    }

    useEffect(() => {
        fetchStatsData();
    }, []);
    return (
        <>
            <Box width={400}>
                <TableContainer>
                    <Toolbar>
                        <Typography variant="h6">Stats report for investors</Typography>
                    </Toolbar>
                    <Table size="small">
                        <TableBody>
                            <StatsTableRow title="Registered Users" value={registeredUsers ?? 0}/>
                            <StatsTableRow title="Registered Children" value={registeredChildren ?? 0}/>
                            <StatsTableRow title="Registered Parents" value={registeredParents ?? 0}/>
                            <StatsTableRow title="Connections" value={parentChildrenConnections ?? 0}/>
                            <StatsTableRow title="KYC" value={kyc ?? 0}/>
                            <StatsTableRow title="Subscriptions" value={subscriptions ?? 0}/>
                            <StatsTableRow title="Active Users" value={activeUsers ?? 0}/>
                            <StatsTableRow title="Referrals Sent" value={referralsSent ?? 0}/>
                            <StatsTableRow title="Referrals Subscribed" value={referralsSubscribed ?? 0}/>
                        </TableBody>
                    </Table>
                    <Table size="small">
                        <TableFooter>
                            <TableRow>
                                <TableCell>
                                    <Box style={{ display: 'flex', width: 25, color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}>
                                        From
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box style={{ display: 'flex', width: 300 }}>
                                        <Input name="filterFrom" type="date" fullWidth inputRef={filterForInvestorsFromRef} defaultValue={defaultFilterFrom}/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Box style={{ display: 'flex', width: 25, color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}>
                                        To
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box style={{ display: 'flex', width: 300 }}>
                                        <Input name="filterTo" type="date" fullWidth inputRef={filterForInvestorsToRef} defaultValue={defaultFilterTo}/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Box style={{ display: 'flex', width: 300 }}>
                                        <Button size="small" variant="contained" onClick={exportForInvestors}>
                                            Export
                                        </Button>
                                        <div style={{width: 30, marginLeft: 5}}>
                                            {fetchingInvestors && <CircularProgress size={22}/>}
                                        </div>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
            <Box width={400}>
                <TableContainer>
                    <Toolbar>
                        <Typography variant="h6">Stats report for marketing</Typography>
                    </Toolbar>
                    <Table size="small">
                        <TableFooter>
                            <TableRow>
                                <TableCell>
                                    <Box style={{ display: 'flex', width: 25, color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}>
                                        From
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box style={{ display: 'flex', width: 300 }}>
                                        <Input name="filterFrom" type="date" fullWidth inputRef={filterForMarketingFromRef} defaultValue={defaultFilterFrom}/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Box style={{ display: 'flex', width: 25, color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}>
                                        To
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box style={{ display: 'flex', width: 300 }}>
                                        <Input name="filterTo" type="date" fullWidth inputRef={filterForMarketingToRef} defaultValue={defaultFilterTo}/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Box style={{ display: 'flex', width: 300 }}>
                                        <Button size="small" variant="contained" onClick={exportForMarketing}>
                                            Export
                                        </Button>
                                        <div style={{width: 30, marginLeft: 5}}>
                                            {fetchingMarketing && <CircularProgress size={22}/>}
                                        </div>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
}
