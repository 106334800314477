import React from "react";
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import MoneyAmountField from "../../../Field/Wallet/MoneyAmountField";
import TopUpEntryAmountField from "../../../Field/Wallet/TopUpEntryAmountField";
import TransactionStatusField from "../../../Field/Wallet/TransactionStatusField";
import useStyles from "../../../../styles";
import BeneficiaryNameAndPhoneField from "../../../Field/Wallet/BeneficiaryNameAndPhoneField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";
import {TopUpTransactionsHeading} from "./TopUpTransactionsHeading";

export const TopUpTransactionsList = (props) => {
    const classes = useStyles();
    return (
        <div>
            <TopUpTransactionsHeading {...props}/>
            <ListConfirmDelete {...props} create={false}>
                <Datagrid>
                    <ResourceIdField cellClassName={classes.narrow}/>
                    <CreatedAtField cellClassName={classes.narrow}/>
                    <TextField source="uuid" cellClassName={classes.narrow}/>
                    <MoneyAmountField cellClassName={classes.narrow}/>
                    <TopUpEntryAmountField cellClassName={classes.narrow}/>
                    <BeneficiaryNameAndPhoneField cellClassName={classes.narrow}/>
                    <TransactionStatusField cellClassName={classes.narrow}/>
                    <ShowButton />
                </Datagrid>
            </ListConfirmDelete>
        </div>
    );
};
