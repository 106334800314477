import React from "react";
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import SmsTypeField from "../../../Field/Sms/SmsTypeField";
import {
    Datagrid,
    ReferenceField,
    ShowButton,
    TextField,
} from 'react-admin';
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import useStyles from "../../../../styles";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export default (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete {...props} create={false}>
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow} />
                <CreatedAtField cellClassName={classes.narrow} />
                <UpdatedAtField cellClassName={classes.narrow} />
                <TextField source="receiver" />
                <ReferenceField label="Initiator" source="initiator" reference="users">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="text" />
                <SmsTypeField component="text" addLabel />
                <TextField source="responseCode" />
                <ShowButton />
            </Datagrid>
        </ListConfirmDelete>
    );
};
