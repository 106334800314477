import { SessionUser } from "./SessionUser";
const storageKey = 'sessionUser';

export const userStorage = {
    setUser: (data: SessionUser | null) => {
        if (data) {
            sessionStorage.setItem(storageKey, JSON.stringify(data));
        } else {
            sessionStorage.removeItem(storageKey);
        }
    },
    getUser: (): SessionUser | null => {
        const data = sessionStorage.getItem(storageKey);
        const decodedData = data ? JSON.parse(data) : null;

        if (decodedData) {
            return new SessionUser(decodedData.name, decodedData.roles, decodedData.expires)
        }

        return null;
    },
    hasUser: (): boolean => {
        return !!sessionStorage.getItem(storageKey);
    },
    removeUser: (): void => {
        sessionStorage.removeItem(storageKey);
    }
}