import React from "react";
import {
    Datagrid,
    ShowButton,
    TextField,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import TimestampField from "../../../Field/Common/TimestampField";
import NotificationStatusField from "../../../Field/Notification/NotificationStatusField";
import useStyles from "../../../../styles";
import UserGroupField from "../../../Field/Notification/UserGroupField";
import YesNoField from "../../../Field/Common/YesNoField";
import { STATUS_COMPLETED } from "../../../../core/Types/StatusCodes";
import StatusAwareEditButton from "../../../Element/Button/StatusAwareEditButton";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export const NotificationsList = (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete {...props} create={false}>
            <Datagrid>
                <ResourceIdField record cellClassName={classes.narrow}/>
                <CreatedAtField record cellClassName={classes.narrow}/>
                <UserGroupField cellClassName={classes.narrow}/>
                <TextField source="message" />
                <TimestampField source="sendingTime" cellClassName={classes.narrow}/>
                <YesNoField record source="pushable" cellClassName={classes.narrow} />
                <NotificationStatusField record cellClassName={classes.narrow}/>
                <ShowButton />
                <StatusAwareEditButton cellClassName={classes.narrow} disabledStatus={STATUS_COMPLETED}/>
            </Datagrid>
        </ListConfirmDelete>
    )
};
