import React from "react";
import {
    BooleanField,
    Datagrid,
    EditButton,
    NumberField,
    ReferenceField,
    RichTextField,
    ShowButton,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export const LessonQuestionAnswersList = (props) => (
    <ListConfirmDelete {...props} create={false}>
        <Datagrid>
            <ResourceIdField record />
            <CreatedAtField record />
            <UpdatedAtField record />
            <ReferenceField label="Lesson question" source="lessonQuestion" reference="lesson-questions">
                <RichTextField source="question" />
            </ReferenceField>
            <RichTextField source="text"/>
            <BooleanField source="correct"/>
            <NumberField source="position"/>
            <ShowButton />
            <EditButton />
        </Datagrid>
    </ListConfirmDelete>
);