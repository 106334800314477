import React from "react";
import {
    BooleanField,
    Datagrid,
    EditButton,
    NumberField,
    ReferenceField,
    ShowButton,
    TextField,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export const RegistrationReasonList = (props) => (
    <ListConfirmDelete
        {...props}
        sort={{ field: 'position', order: 'ASC' }}
    >
        <Datagrid>
            <ResourceIdField record />
            <CreatedAtField record />
            <UpdatedAtField record />
            <TextField source="title"/>
            <NumberField source="position"/>
            <BooleanField source="published"/>
            <BooleanField source="hasCustomText"/>
            <EditButton />
        </Datagrid>
    </ListConfirmDelete>
);