import React from "react";
import {
    BooleanInput,
    Edit,
    NumberInput,
    SimpleForm,
    TextField,
    TextInput,
    required,
    Toolbar,
    SaveButton,
    DeleteButton
} from 'react-admin';
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import LastLoginField from "../../../Field/Common/LastLoginField";
import TimestampField from "../../../Field/Common/TimestampField";
import { makeStyles } from '@material-ui/core/styles';
import {userStorage} from "../../../../core/Model/SessionUser/userStorage";
import {ROLE_ADMIN} from "../../../../core/Types/roles";
let isAdmin = userStorage.getUser()?.isAdmin();

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
        {isAdmin && (  <DeleteButton undoable={false} /> )}
    </Toolbar>
);

export const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
            <TextInput source="name" validate={required()} />
            <TextInput source="email" />
            <TextInput source="phone" validate={required()} />
            <TextInput source="adminComment" multiline defaultValue=""/>
            <BooleanInput source="isActive"/>
            <BooleanInput source="newsletterEnabled"/>
            <BooleanInput source="smsEnabled"/>
            <BooleanInput source="subscriptionStatus"/>
            <NumberInput source="interestRatesPercent" step={1} min={0} />
            <CreatedAtField addLabel/>
            <UpdatedAtField addLabel/>
            <TextField source="lastPhoneUpdateInfo" />
            <TimestampField addLabel source="lastPhoneUpdateDate" />
            <LastLoginField addLabel/>
        </SimpleForm>
    </Edit>
);