import React from "react";
import EnumTypeField from "../Common/EnumTypeField";

import {
    TOPIC_CATEGORY_ADVANCED,
    TOPIC_CATEGORY_BASIC
} from "../../../core/Types/LessonTopicCategoryTypes";

class LessonTopicCategoryField extends EnumTypeField
{
    static defaultProps = {
        source: 'category',
        label: 'Category',
        component: 'text',
    };

    getEnumMap(): object {
        return {
            [TOPIC_CATEGORY_BASIC]: 'Basic',
            [TOPIC_CATEGORY_ADVANCED]: 'Advanced',
        }
    }

    // getColorMap(): object {
    //     return {
    //         [STATUS_COMPLETED]: 'primary'
    //     }
    // }
    //
    // getIconMap(): object {
    //     return {
    //         [STATUS_COMPLETED]: <CheckCircle />,
    //         [STATUS_PENDING]: <Timelapse />,
    //     }
    // }
}

export default LessonTopicCategoryField;
