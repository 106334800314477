import {FieldProps} from "../../../core/Types/common";
import React from "react";
import { availableLocales, defaultLocaleCode } from "../../../core/Types/locales";
import Flag from 'react-world-flags';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import StorageManager from "../../../utils/StorageManager";

interface Props extends FieldProps
{
    baseUrl: string
}

const useStyles = makeStyles({
    select: {
        width: 130,
    },
    widget: {
        // width: (60 + 24 * locales.length),
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
    },
    selectedButton: {
        backgroundColor: 'white !important',
    },
    button: {},
    flag: {
        height: 16,
        width: 24,
        margin: '4px 0'
    },
});

const TranslatableSwitchTool = (props: Props) => {
    const classes = useStyles();
    const selectedLocaleCode = StorageManager.getSelectedLocale() || defaultLocaleCode;

    return (
        <Box display="flex" justifyContent="end">
            <ButtonGroup variant="contained" size="small">
                {availableLocales.map(item => {
                    // @ts-ignore
                    const { code, countryCode } = item;
                    let _selected = selectedLocaleCode === code;

                    return (
                        <Button
                            key={item.code}
                            disabled={_selected}
                            className={_selected ? classes.selectedButton : classes.button}
                            onClick={e => {
                                StorageManager.setSelectedLocale(code);
                                window.location.reload();
                            }}
                        >
                            <Flag code={countryCode} className={classes.flag}/>
                        </Button>
                    );
                })}
            </ButtonGroup>
        </Box>
    );
};

export default TranslatableSwitchTool;
