import React from "react";
import {
    Datagrid,
    ReferenceField,
    TextField,
    ShowButton,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import useStyles from "../../../../styles";
import MoneyAmountField from "../../../Field/Wallet/MoneyAmountField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";
import YesNoField from "../../../Field/Common/YesNoField";
import SubscriptionExpireStatusField from "../../../Field/UserSubscription/SubscriptionExpireStatusField";
import UserField from "../../../Field/Common/UserField";
import ParentField from "../../../Field/Common/ParentField";

export default (props) => {
    const classes = useStyles();

    return (
        <ListConfirmDelete {...props} sort={{ field: 'createdAt', order: 'desc' }} create={false}>
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow} />
                <UserField label="Subscriber" />
                <ParentField />
                <CreatedAtField />
                <SubscriptionExpireStatusField source="subscriptionExpiry"/>
                <YesNoField source="autoSubscribe"/>
                <MoneyAmountField source="priceWithoutDiscount"/>
                <MoneyAmountField source="priceWithDiscount"/>
                <TextField source="discountCodePlain" />
                <SubscriptionExpireStatusField source="discountExpiry"/>
                <UpdatedAtField />
                <ShowButton />
            </Datagrid>
        </ListConfirmDelete>
    );
};