export const BankAccountIssues = {
    '0': 'Unassigned',
    '1': 'Duplicate account (auto delete)',
    '2': 'Media awareness (check support mail for docs)',
    '3': 'Bad KYC (auto reset)',
    '4': 'Missing KYC info (auto resolve)',
    '5': 'Fraud alert (user already blocked, waiting for documents)',
    '6': 'Delete account (auto delete)',
    '7': 'PEP (check support mail for docs)',
    '8': 'Underage parent (check support mail for child docs)',
    '9': 'Resolve manually',
}
