import React from "react";
import { FieldProps } from "../../../core/Types/common";
import BooleanChip from "../../Element/Chip/BooleanChip";
import { TimerOff, Timer } from '@material-ui/icons';
import config from "../../../config";
import { getCurrentDateInAwsFormat } from "../../../utils/dateHelper";

const SubscriptionExpireStatusField = (props: FieldProps) => {
    const { record, source } = props;
    let customColor = record['backupUserId'] ? '#cc0000' : null

    return <BooleanChip
        customColor={customColor}
        value={record[source] > getCurrentDateInAwsFormat()}
        labelTrue={record[source] ? record[source].substring(0, 10) : 'Unset'}
        labelFalse={record[source] ? record[source].substring(0, 10) : 'Unset'}
        iconFalse={<TimerOff />}
        iconTrue={<Timer />}
    />;
}

export default SubscriptionExpireStatusField;